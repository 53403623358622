<template>
    <div class="print-table" v-for="(record, recordIndex) in shippingRecords" :key="recordIndex" :class="recordIndex != 0 && 'mt-10'">
        <div class="print-table-row mb-1" v-if="record.address">
            <div class="table-responsive">
                <table class="print-table-information">
                    <tr>
                        <th colspan="3" class="print-table-header">{{ $t('pages.ecommerce.order.shipping.preview.cols.address.title') }}</th>
                    </tr>
                    <tr>
                        <th>{{ $t('pages.ecommerce.order.shipping.preview.cols.address.cols.fullName') }}</th>
                        <td class="fw-bolder">:</td>
                        <td>{{ record.address.full_name }}</td>
                    </tr>
                    <tr>
                        <th>{{ $t('pages.ecommerce.order.shipping.preview.cols.address.cols.phone') }}</th>
                        <td class="fw-bolder">:</td>
                        <td>{{ record.address.mobile_phone ?? "-" }}</td>
                    </tr>
                    <tr>
                        <th>{{ $t('pages.ecommerce.order.shipping.preview.cols.address.cols.address') }}</th>
                        <td class="fw-bolder">:</td>
                        <td>
                            <div>{{ record.address.address }}</div>
                            <div>{{ sprintf('%s / %s / %s', [((record.address.district && record.address.district.trim().length) ? record.address.district : "-"), (record.address.city ? record.address.city.name : "-"), (record.address.state ? record.address.state.name : "-")])}}</div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="print-table-row mb-1" v-if="record.shipping_detail">
            <div class="table-responsive">
                <table class="print-table-information">
                    <tr>
                        <th colspan="3" class="print-table-header">{{ $t('pages.ecommerce.order.shipping.preview.cols.shippingDetail.title') }}</th>
                    </tr>
                    <tr v-if="record.payment_detail">
                        <th>{{ $t('pages.ecommerce.order.shipping.preview.cols.shippingDetail.cols.paymentType') }}</th>
                        <td class="fw-bolder">:</td>
                        <td>{{ record.payment_detail.payment_method ? record.payment_detail.payment_method.name: "-" }}</td>
                    </tr>
                    <tr>
                        <th>{{ $t('pages.ecommerce.order.shipping.preview.cols.shippingDetail.cols.shippingMethod') }}</th>
                        <td class="fw-bolder">:</td>
                        <td>{{ record.shipping_detail.shipping_method ? record.shipping_detail.shipping_method.name : "-" }}</td>
                    </tr>
                    <tr>
                        <th>{{ $t('pages.ecommerce.order.shipping.preview.cols.shippingDetail.cols.shippingType') }}</th>
                        <td class="fw-bolder">:</td>
                        <td>{{ $t('pages.ecommerce.order.shipping.preview.shippingTypeValue')}}</td>
                    </tr>
                    <tr>
                        <th>{{ $t('pages.ecommerce.order.shipping.preview.cols.shippingDetail.cols.shippingBarcodeNo') }}</th>
                        <td class="fw-bolder">:</td>
                        <td>
                            <CustomBarcode v-if="getBarcode(record).path" :value="getBarcode(record).path" :format="getBarcode(record).type" :lineColor="'#000'" :width="1.6" :height="25" :elementTag="'img'" />
                            <div v-else>-</div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="print-table-row mb-1" v-if="record.items">
            <div class="table-responsive">
                <table class="print-table-information">
                    <tr>
                        <th colspan="3" class="print-table-header">{{ $t('pages.ecommerce.order.shipping.preview.cols.item.title') }}</th>
                    </tr>
                    <tr>
                        <th class="print-table-product-header">{{ $t('pages.ecommerce.order.shipping.preview.cols.item.cols.image') }}</th>
                        <th class="print-table-product-header">{{ $t('pages.ecommerce.order.shipping.preview.cols.item.cols.name') }}</th>
                        <th class="print-table-product-header"></th>
                    </tr>
                    <tr class="print-table-product-line" v-for="(item, itemIndex) in record.items" :key="itemIndex" :class="itemIndex == 0 && 'print-table-firs-line'">
                        <td>
                            <img class="print-table-image" :src="item.product && item.product.image ? item.product.image.thumb.small : '/media/svg/files/blank-image.svg'">
                        </td>
                        <td>{{ sprintf('%s x%d', [item.title, item.quantity])}}</td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import CustomBarcode from "@/components/custom-barcode";

export default {
    layout: 'preview',
    name: "_id",
    components: {
        CustomBarcode
    },
    data() {
        return {
            shippingRecords: [],
            barcodeMapping: {
                ptt: {
                    path: 'shipping_detail.shipping_tracking_number',
                    type: "CODE39",
                },
                sendeo: {
                    path: 'shipping_detail.shipping_tracking_detail.reference_no',
                    type: "CODE128",
                }
            },
            getCurrentBarcode: {},
        }
    },
    computed: {
        orderIDs() {
            return this.$route.params.id.split(',');
        },
    },
    created() {
        if (!this.orderIDs || !(this.orderIDs.length > 0)) {
            this.$router.push({
                path: "/ecommerce/order-management/shipping"
            });
        }
    },
    mounted() {
        if (this.orderIDs && this.orderIDs.length > 0) {
            this.loadShippingPreview();
        }
    },
    methods: {
        loadShippingPreview() {
            this.axios.get(this.endpoints['ecommerce_order_shipping_waybill_preview'], {
                params: {
                    orders: this.orderIDs
                }
            }).then((response) => {
                let data = response.data.data;
                this.shippingRecords = data.map((record) => {
                    if(record.items && record.items.length) {
                        record.items = record.items.filter(item => item.product && item.product.shipping != -1);
                    }

                    return record;
                });
            }).finally(() => {
                setTimeout(() => {
                    window.print();
                    window.onfocus = function(){ window.close(); }
                }, 500)
            })
        },
        getBarcode(record, defaultPath = 'shipping_detail.shipping_tracking_number', defaultType = 'CODE39'){
            let obj = { path: defaultPath, type: defaultType };

            if(record.shipping_detail && record.shipping_detail.shipping_method && this.barcodeMapping[record.shipping_detail.shipping_method.code.toLowerCase()]) {
                obj = this.cloneData(this.barcodeMapping[record.shipping_detail.shipping_method.code.toLowerCase()]);
            }

            obj.path = this.objectPath.get(record, obj.path);

            return obj;
        }
    }
}
</script>

<style>
table tr td, table tr th {
    padding: 0 .75rem !important;
    font-size: 1.25rem !important;
    vertical-align: top;
}

table tr th {
    font-weight: 700 !important;
}

.print-table {
    page-break-after: always;
}

.print-table .print-table-information {
    color: #333 !important;
    margin-bottom: 0 !important;
    border-collapse: collapse;
    border: 1px solid #000;
    width: 100%;
}

.print-table .print-table-information tr th {
    font-weight: 700 !important;
    width: 200px !important;
}

.print-table .print-table-information td:nth-child(2) {
    width: 25px !important;
}

.print-table .print-table-information tr:nth-child(2) > *{
    padding-top: .75rem !important;
}

.print-table .print-table-information tr:last-child > *{
    padding-bottom: .75rem !important;
}

.print-table .print-table-header, .print-table .print-table-product-header {
    padding: .75rem !important;
    font-size: 1.4rem !important;
    border-bottom: 1px solid #000 !important;
    white-space: nowrap;
}

.print-table .print-table-product-header {
    font-size: 1.25rem !important;
}

.print-table .print-table-image {
    width: 60px;
    height: 60px;
    object-fit: scale-down;
}

.print-table .print-table-information .print-table-firs-line > * {
    padding-top: .75rem !important;
}

.print-table .print-table-information .print-table-product-line > * {
    vertical-align: middle;
}

.print-table #barcodegen {
    margin-left: -7px;
    max-width: 300px;
    object-fit: contain;
}

@media screen and (max-width: 767px) {
    .print-table .print-table-information tr th {
        width: auto !important;
    }

    .print-table .print-table-information td:nth-child(2) {
        width: auto !important;
    }
}


</style>